import React, { useEffect, useState } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { TbTruckDelivery, TbReplace } from "react-icons/tb";

const Lab = ({ singleProduct }) => {
  const {
    id: alias,
    name,
    sample,
    price,
    test_freq,
    method,
    reported_on,
    code,
    stock = 5,
  } = singleProduct;
  return (
    <>
      <div className="product-data-warranty">
        <div className="product-warranty-data">
          <TbTruckDelivery className="warranty-icon" />
          <p>Free Delivery</p>
        </div>

        <div className="product-warranty-data">
          <TbReplace className="warranty-icon" />
          <p>7 days Replacement</p>
        </div>

        <div className="product-warranty-data">
          <TbTruckDelivery className="warranty-icon" />
          <p>Home Service </p>
        </div>
      </div>

      <div className="product-data-info">
        <p>
          Sample: <span>{sample}</span>
        </p>
        <p>
          Test Frequency:
          <span> {test_freq}</span>
        </p>
        <p>
          Method:
          <span> {method}</span>
        </p>
        <p>
          Reported on:
          <span> {reported_on}</span>
        </p>
        <p>
          Code : <span> {code} </span>
        </p>
      </div>
    </>
  );
};

export default Lab;

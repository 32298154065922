import { MDBContainer } from "mdb-react-ui-kit";
import BillingAddress from "./components/checkout/BillingAddress";
import ShippingAddress from "./components/checkout/ShippingAddress";
import PaymentMethod from "./components/checkout/PaymentMethod";
import ImageUpload from "./components/checkout/ImageUpload";
import { useCartContext } from "./context/cartcontext";
import { useOrderContext } from "./context/ordercontext";
import axios from "axios";
import { useState, useEffect } from "react";
import SideCart from "./components/checkout/SideCart";

const Checkout = () => {
  const { total_price, shipping_fee } = useCartContext();
  const { state, cartDetails } = useOrderContext();
  const [orderId, setOrderId] = useState(null);
  const [imageFiles, setImageFiles] = useState([]); // Array to hold multiple image files

  // Function to upload the image
  const uploadImage = async () => {
    if (imageFiles.length === 0) {
      return [];
    }

    // Initialize FormData and append all image files
    const formData = new FormData();
    imageFiles.forEach((file) => {
      formData.append("files", file); // Append each image file with the key 'files'
    });

    try {
      // Send a POST request to the backend to upload the images
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/order/uploadPrescription`,
        formData,
        {
          headers: {
            "auth-token": localStorage.getItem("authToken"),
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        }
      );

      // Extract the file URLs from the response and return them
      const uploadedImageUrls = response.data.fileUrls;
      return uploadedImageUrls; // Return the array of uploaded image URLs
    } catch (error) {
      console.error("Error uploading the images:", error);
      return []; // Return an empty array if the upload fails
    }
  };

  const onlinePayment = async () => {
    const order = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND}/api/payment/createorder`,
      data: {
        amount: total_price + shipping_fee,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { id: order_id, amount } = order.data;

    const options = {
      key: "rzp_test_dKhJ2jiI5qasOl", // Enter the Key ID generated from the Dashboard
      amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: `${process.env.REACT_APP_COMPANY_NAME}`,
      description: "Test Transaction",
      image: `${process.env.REACT_APP_COMPANY_LOGO}`,
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: `${process.env.REACT_APP_BACKEND}/api/payment/success/${orderId}`,
      // callback_url: "http://localhost:3000/try",
      prefill: {
        name: `${state.billingInfo.firstName} ${state.billingInfo.lastName}`,
        email: `${state.billingInfo.email}`,
        contact: `${state.billingInfo.phone}`,
      },
      notes: {
        address: "Razorpay Corporate Office",
        cart: JSON.stringify(cartDetails),
      },
      theme: {
        color: "#3399cc",
      },
    };
    console.log(options);
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const offlinePayment = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND}/api/refferal/updateCredits/${orderId}`
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Referral updated successfully:", response.data);
          // Navigate to the success page after a successful API call
          window.location.href = "/success";
        } else {
          console.log("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error(
          "Error calling referral update API:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const placeOrderMongo = async (paymentStatus, imageUrls) => {
    const payload = {
      ...state,
      Amount:
        state.cartDetails.total_price +
        state.cartDetails.shipping_fee -
        state.additionalDiscount,
      paymentStatus: paymentStatus,
      prescription: {
        imageUrls, // Send array of image URLs
      },
    };

    console.log("palce order ", payload);
    axios
      .post(`${process.env.REACT_APP_BACKEND}/api/order/placeOrder`, payload, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        console.log("result of the order placed ---> ", res);
        setOrderId(res.data.orderId);
        localStorage.setItem("orderId", res.data.orderId);

        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    try {
      // First upload the image
      var imageUrls = await uploadImage();

      if (!imageUrls.length === 0) {
        console.log("no image to upload ");
        imageUrls = ["https://roghar.s3.ap-south-1.amazonaws.com/medicine.jpg"];
        // return;
      }

      // If the image is successfully uploaded, proceed with placing the order
      const paymentStatus =
        state.paymentDetails.modeOfPayment === "COD" ? "Success" : "Pending";

      const res = await placeOrderMongo(paymentStatus, imageUrls);
      console.log("Order placed successfully with prescription image:", res);
    } catch (error) {
      console.error("Error placing order:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (orderId) {
      if (state.paymentDetails.modeOfPayment === "COD") {
        offlinePayment();
      } else {
        onlinePayment(); // Assuming you have an onlinePayment function
      }
    } else {
      console.error("Failed to place order, no orderId returned.. ");
    }
  }, [orderId]);

  return (
    <>
      <MDBContainer className="my-5 py-5" style={{ maxWidth: "1100px" }}>
        <SideCart />
        <form onSubmit={HandleSubmit}>
          <ImageUpload setImageFiles={setImageFiles} />{" "}
          {console.log("state of order -->", state)}
          <BillingAddress />
          <ShippingAddress />
          <PaymentMethod />
          <div className="text-center">
            <button
              type="Submit"
              className="btn btn-success btn-block mx-auto"
              style={{
                height: "50px",
                fontSize: "16px",
                borderRadius: "10px",
                width: "50%",
              }}
            >
              Place order
            </button>
          </div>
        </form>
      </MDBContainer>
    </>
  );
};

export default Checkout;

import React, { useRef, useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRcode = ({ referralCode }) => {
  const canvasRef = useRef(null);

  const encodedReferralCode = btoa(referralCode); // Base64 encode the referral code
  const registrationUrl = `${process.env.REACT_APP_COMPANY_SITE}/login?code=${encodedReferralCode}`;

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "QRcard.png";
    link.click();
  };

  const drawImageWithQR = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Load the background image (the card)
    const backgroundImage = new Image();
    backgroundImage.crossOrigin = "anonymous";
    backgroundImage.src = "https://roghar.s3.ap-south-1.amazonaws.com/1.png"; // Path to your card image

    // When the background image is loaded, draw it on the canvas
    backgroundImage.onload = () => {
      // Adjust the canvas size to match the image size
      canvas.width = backgroundImage.width;
      canvas.height = backgroundImage.height;

      // Draw the background image on the canvas
      ctx.drawImage(backgroundImage, 0, 0);

      // Create the QR code with a custom background (not white) for download
      const qrCodeCanvas = document.querySelector(".qr-code-canvas");

      // Create a secondary canvas to manipulate the QR code's background color
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");

      tempCanvas.width = qrCodeCanvas.width;
      tempCanvas.height = qrCodeCanvas.height;

      // Fill the background with the new color for download
      tempCtx.fillStyle = "#d9d9d9"; // Replace white with #d9d9d9 for download
      tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      // Draw the QR code on top
      tempCtx.drawImage(qrCodeCanvas, 0, 0);

      // Convert the temporary canvas with the modified background to an image
      const qrImage = new Image();
      qrImage.src = tempCanvas.toDataURL();

      qrImage.onload = () => {
        // Define the position where you want to place the QR code on the image
        const qrX = 684; // X position for the QR code
        const qrY = 122; // Y position for the QR code
        const qrSize = 229; // Size of the QR code

        // Draw the QR code image onto the main canvas
        ctx.drawImage(qrImage, qrX, qrY, qrSize, qrSize);

        // Add text on the card
        ctx.font = "25px Telegraf"; // Set font size and family
        ctx.fillStyle = "#000000"; // Text color
        ctx.fillText(referralCode, 740, 425); // Draw text at position (50, 100)
      };
    };
  };

  // Render the QR code and draw the final image when the component mounts
  useEffect(() => {
    if (canvasRef.current) {
      drawImageWithQR();
    }
  }, [referralCode]);

  return (
    <div>
      <h4>Referral QRCode</h4>
      {/* <QRCodeCanvas value={registrationUrl} /> */}

      <QRCodeCanvas
        value={registrationUrl}
        // bgColor="#d9d9d9"
        className="qr-code-canvas"
      />

      {/* This canvas will be used to combine the card and QR code */}
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

      <p>Refferal Link: {registrationUrl}</p>
      <button onClick={handleDownload}>Download QRcard</button>
    </div>
  );
};

export default QRcode;

import React, { useState } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import styled from "styled-components";

const ImageUpload = ({ setImageFiles }) => {
  const [fileNames, setFileNames] = useState([]);

  const handleImageChange = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files); // Get selected files as an array
    if (files.length > 0) {
      const names = files.map((file) => file.name);
      setFileNames(names);
      setImageFiles(files); // Pass the files back to the parent component
    }
  };

  return (
    <Wrapper>
      <MDBContainer>
        <h4>Upload Prescription</h4>
        <MDBRow>
          <div>
            <input
              type="file"
              accept=".jpg, .png, .jpeg"
              multiple // Allow multiple file selection
              onChange={handleImageChange}
            />
            {fileNames.length > 0 && (
              <ul>
                {fileNames.map((name, index) => (
                  <li key={index}>Selected file: {name}</li>
                ))}
              </ul>
            )}{" "}
            {/* Display selected file names */}
          </div>
        </MDBRow>
      </MDBContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 2rem 0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  h4 {
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 15px 0;
  }
  label {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 5px;
    span {
      color: red;
    }
  }
  input {
    width: 100%;
    font-size: 14px;
    padding: 8px 12px;
    text-transform: lowercase;
    outline: #ccc;
    box-shadow: none;
    border: 1px solid #ccc;
  }
  input:focus {
    outline: #ff7f23 auto 1px;
  }
  ul {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    list-style: none;
    padding: 0;
  }
  li {
    margin-bottom: 5px;
  }
`;

export default ImageUpload;

import React, { useState, useEffect } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import axios from "axios";
import styled from "styled-components";
import DatePicker from "react-datepicker"; // You can install using 'npm install react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const Admin = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Orders");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // For collapsible menu

  const fetchOrdersByDateRange = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/order/date-range`,
        {
          params: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      );
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/users`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/products`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (selectedOption === "Orders") {
      fetchOrdersByDateRange();
    } else if (selectedOption === "Users") {
      fetchUsers();
    } else if (selectedOption === "Products") {
      fetchProducts();
    }
  }, [selectedOption]);

  return (
    <div>
      <h2>Admin Dashboard - {selectedOption}</h2>
      <Wrapper>
        <MDBContainer className="py-5">
          <MDBRow>
            <MDBCol lg="4">
              <div
                className="menu-toggle"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <button>{isMenuOpen ? "Close Menu" : "Open Menu"}</button>
              </div>
              <div className={`collapsible-menu ${isMenuOpen ? "open" : ""}`}>
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <h4>Options</h4>
                    <label
                      className={selectedOption === "Users" ? "active" : ""}
                      onClick={() => setSelectedOption("Users")}
                    >
                      Users
                    </label>
                    <label
                      className={selectedOption === "Orders" ? "active" : ""}
                      onClick={() => setSelectedOption("Orders")}
                    >
                      Orders
                    </label>
                    <label
                      className={selectedOption === "Products" ? "active" : ""}
                      onClick={() => setSelectedOption("Products")}
                    >
                      Products
                    </label>
                  </MDBCardBody>
                </MDBCard>
              </div>
            </MDBCol>
            <MDBCol lg="8">
              <MDBCard className="mb-4">
                <MDBCardBody>
                  {selectedOption === "Orders" && (
                    <div>
                      <h4>Orders List</h4>
                      {orders.map((order) => (
                        <div key={order._id}>
                          <p>Order ID: {order._id}</p>
                          <p>Customer: {order.customerName}</p>
                          <p>
                            Date: {new Date(order.date).toLocaleDateString()}
                          </p>
                          <hr />
                        </div>
                      ))}
                    </div>
                  )}
                  {selectedOption === "Users" && (
                    <div>
                      <h4>Users List</h4>
                      {users.map((user) => (
                        <div key={user._id}>
                          <p>User ID: {user._id}</p>
                          <p>Name: {user.name}</p>
                          <p>Email: {user.email}</p>
                          <hr />
                        </div>
                      ))}
                    </div>
                  )}
                  {selectedOption === "Products" && (
                    <div>
                      <h4>Products List</h4>
                      {products.map((product) => (
                        <div key={product._id}>
                          <p>Product ID: {product._id}</p>
                          <p>Name: {product.name}</p>
                          <p>Price: {product.price}</p>
                          <hr />
                        </div>
                      ))}
                    </div>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.section`
  .menu-toggle {
    display: none;
  }

  @media (max-width: 768px) {
    .menu-toggle {
      display: block;
      margin-bottom: 10px;
    }

    .collapsible-menu {
      display: none;
    }

    .collapsible-menu.open {
      display: block;
    }

    label {
      display: block;
      cursor: pointer;
      padding: 10px;
      background-color: #f0f0f0;
      border-radius: 4px;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  label:hover {
    background-color: #d0d0d0;
  }

  label.active {
    background-color: #007bff;
    color: #fff;
  }
`;

export default Admin;

import React, { useEffect, useState } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { TbTruckDelivery, TbReplace } from "react-icons/tb";

const Medicine = ({ singleProduct }) => {
  const stock = 5;
  return (
    <>
      <div className="product-data-warranty">
        <div className="product-warranty-data">
          <TbTruckDelivery className="warranty-icon" />
          <p>Free Delivery</p>
        </div>

        <div className="product-warranty-data">
          <TbReplace className="warranty-icon" />
          <p>7 days Replacement</p>
        </div>

        <div className="product-warranty-data">
          <TbTruckDelivery className="warranty-icon" />
          <p>Home Service </p>
        </div>

        {/* <div className="product-warranty-data">
                <MdSecurity className="warranty-icon" />
                <p>2 Year Warranty </p>
              </div> */}
      </div>

      <div className="product-data-info">
        <p>
          Pack: <span>{singleProduct.pack}</span>
        </p>
        <p>
          Available:
          <span> {stock > 0 ? "In Stock" : "Not Available"}</span>
        </p>
        <p>
          Code : <span> {singleProduct.code} </span>
        </p>
        <p>
          Brand :<span> {singleProduct.company} </span>
        </p>
      </div>
    </>
  );
};

export default Medicine;

import React from "react";
import Services from "./components/home/Services";
import FeatureProduct from "./components/home/FeatureProduct";
import FAQs from "./components/home/FAQs";
import Carousel from "./components/home/Carousel";
import FilterSection from "./components/filter/FilterSection";
import { useFilterContext } from "./context/filtercontext";
import "./styles/Home.css";
import Sort from "./components/filter/Sort";
import ProductList from "./components/product/ProductList";
import Loader from "./components/Loader";
import { Button } from "./styles/Button";
import { NavLink } from "react-router-dom";

const Home = () => {
  const {
    filters: { text, category },
    loading,
  } = useFilterContext();

  return (
    <>
      <FilterSection />
      {text !== "" ? (
        <div className="container product-view--sort">
          {loading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <>
              <div className="sort-filter">
                <Sort />
              </div>
              <div className="main-product">
                <ProductList />
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          {/* Horizontal line with OR */}
          <div className="or-divider">
            <hr className="line" />
            <span className="or-text">OR</span>
            <hr className="line" />
          </div>

          <div className="text-option">
            <p>What are you looking for:</p>
          </div>

          {/* Centered Button */}
          <div className="center-button">
            <NavLink>
              <Button
                className="bttn-primary"
                style={{
                  backgroundColor: "#2f5c90", // Blue primary color
                }}
              >
                Book Sample Collection
              </Button>
            </NavLink>

            <NavLink to={`/orderbyprescription`}>
              <Button
                className="bttn-primary"
                style={{
                  backgroundColor: "#348a35", // Blue primary color
                }}
              >
                Upload a Prescription
              </Button>
            </NavLink>
          </div>
          <Carousel />
          <FeatureProduct />
          <FAQs />
          <Services />
        </>
      )}
    </>
  );
};

export default Home;

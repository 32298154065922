/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { useProductContext } from "./context/productcontext";
import { useFilterContext } from "./context/filtercontext";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import PageNavigation from "./components/layout/PageNavigation";
import MyImage from "./components/MyImage";
import { Container } from "./styles/Container";
import FormatPrice from "./utils/FormatPrice";
import imagePath from "./utils/medicine.jpg";
import AddToCart from "./components/AddToCart";
import Medicine from "./components/singleProduct/Medicine";
import Lab from "./components/singleProduct/Lab";

// const API = "https://api.pujakaitem.com/api/products";
console.log(
  process.env.REACT_APP_BACKEND_HOST,
  process.env.REACT_APP_BACKEND_PORT
);
const API = `${process.env.REACT_APP_BACKEND}/api/products`;

const SingleProduct = () => {
  const { getSingleProduct, isSingleLoading, singleProduct } =
    useProductContext();

  const {
    filters: { text, category },
  } = useFilterContext();
  const { id } = useParams();

  // console.log(singleProduct);

  const { id: alias, name, price, MRP, stock = 5 } = singleProduct;

  useEffect(() => {
    getSingleProduct(`${API}/${id}`, category);
  }, []);

  if (isSingleLoading) {
    return <div className="page_loading">Loading ... </div>;
  }
  return (
    <Wrapper>
      <PageNavigation title={name} />
      <Container className="container">
        <div className="grid grid-two-column">
          <div className="product_images">
            <MyImage imgs={[imagePath]} />
          </div>

          <div className="product-data">
            <h2 style={{ textTransform: "capitalize", fontSize: "30px" }}>
              {name}
            </h2>
            <p>
              <b>MRP: </b>
              <del>
                <FormatPrice price={MRP} />
              </del>
            </p>
            <p className="product-data-price product-data-real-price">
              Discounted Price: <FormatPrice price={price} />
            </p>

            {category == "MEDICINE" ? (
              <Medicine singleProduct={singleProduct} />
            ) : (
              <Lab singleProduct={singleProduct} />
            )}
            {/* <Lab singleProduct={singleProduct} /> */}

            <hr />
            {stock > 0 && <AddToCart product={singleProduct} />}
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100vw;
  .container {
    padding: 9rem 0;
  }
  .product_images {
    display: flex;
    align-items: center;
  }
  .product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    .product-data-warranty {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;
      .product-warranty-data {
        text-align: center;
        .warranty-icon {
          background-color: rgba(220, 220, 220, 0.5);
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          padding: 0.6rem;
        }
        p {
          font-size: 1.4rem;
          padding-top: 0.4rem;
        }
      }
    }
    .product-data-price {
      font-weight: bold;
    }
    .product-data-real-price {
      color: ${({ theme }) => theme.colors.btn};
    }
    .product-data-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.8rem;
      span {
        font-weight: bold;
      }
    }
    hr {
      max-width: 100%;
      width: 90%;
      /* height: 0.2rem; */
      border: 0.1rem solid #000;
      color: red;
    }
  }
  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page_loading {
    font-size: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 2.4rem;
  }
`;

export default SingleProduct;
